/* ------- */
/* ACCUEIL */
/* ------- */

$(function () {

    /**
     * Click alert :
     * -> la supprime.
     */
    const alerts = $('body#accueil div.alert:not(.alert-inscription, .alert-reset)');
    $(alerts).on('click', function () {
        const currentAlert = $(this);
        $(this).css({
            'opacity': "0",
        });
        setTimeout(function () {
            $(currentAlert).remove();
        }, 475);
    });


    /**
     * LOGIN (Téléchargements)
     */
    const fondModLogin = $('body#accueil > section#login');
    if ($(fondModLogin).length) {
        const btnLoginHeader = $('body#accueil > header > nav.navbar > div#navbarSupportedContent > ul.navbar-nav > li:nth-of-type(2) > a');
        const btnLoginFooter = $('body#accueil > footer > div.container > div.row > article:nth-of-type(2) > ul.list-unstyled > li:nth-of-type(2) > a');
        const modLogin = $('body#accueil > section#login > article.modal-content');
        /**
         * Gestion redirection depuis createPwd et resetPwd.
         */
        if (sessionStorage.getItem('from') == 'createPwd' || sessionStorage.getItem('from') == 'resetPwd') {
            sessionStorage.removeItem('from');
            $(fondModLogin).removeClass('hidden');
        } else {}
        /**
         * Click btn navbar header "Téléchargements" :
         * -> ouvre le modal de login.
         */
        $(btnLoginHeader).on('click', function (event) {
            event.preventDefault();
            $(fondModLogin).removeClass('hidden');
        });
        /**
         * Click btn navbar footer "Téléchargements" :
         * -> ouvre le modal de login.
         */
        $(btnLoginFooter).on('click', function (event) {
            event.preventDefault();
            $(fondModLogin).removeClass('hidden');
        });
        /**
         * Click sur bouton close :
         * -> ferme le modal,
         * -> supprime alerts.
         */
        const btnCloseLogin = $('body#accueil > section#login > article.modal-content > div.modal-header > i');
        $(btnCloseLogin).on('click', function () {
            $(fondModLogin).addClass('hidden');
            $(alerts).remove();
        });
        /**
         * Click sur bouton 'Annuler' :
         * -> ferme le modal,
         * -> supprime alerts,
         * -> vide l'input email (nécessaire après redirect()->back()->withInputs() qui set la 'default value' avec old()).
         */
        const btnAnnulerLogin = $('body#accueil > section#login > article.modal-content > div.modal-body > form > div.card-footer > button[type=reset]');
        $(btnAnnulerLogin).on('click', function () {
            $(fondModLogin).addClass('hidden');
            $(alerts).remove();
            const inputEmail = $('body#accueil > section#login input#identifiant');
            $(inputEmail).removeAttr('value');
        });
        /**
         * Click sur lien 'Reset mdp' :
         * -> ferme le modal et ouvre celui de reset mdp.
         */
        const resetMdp = $('body#accueil > section#login > article.modal-content > div.modal-body > form > div.card-body > div > fieldset:last-of-type > a:first-of-type');
        $(resetMdp).on('click', function (event) {
            event.preventDefault();
            $(fondModLogin).addClass('hidden');
            $(alerts).remove();
            $('body#accueil > section#reset-mdp').removeClass('hidden');
        });
        /**
         * Clique sur lien 'S'inscrire' :
         * -> ferme le modal et ouvre celui d'inscription.
         */
        const notLoggedYet = $('body#accueil > section#login > article.modal-content > div.modal-body > form > div.card-body > div > fieldset:last-of-type > a:last-of-type');
        $(notLoggedYet).on('click', function (event) {
            event.preventDefault();
            $(fondModLogin).addClass('hidden');
            $(alerts).remove();
            $('body#accueil > section#inscription').removeClass('hidden');
        });
        /**
         * Gestion alerts :
         * -> si erreur ou alert, trigger modal.
         */
        if ($('body#accueil > section#login > article.modal-content > div.modal-body > div.alert ').length) {
            $(btnLoginHeader).trigger('click');
        } else {}
        if ($('body#accueil > section#login > article.modal-content > div.modal-body > form > div.card-body > fieldset > div.form-group > div.invalid-feedback').length) {
            $(btnLoginHeader).trigger('click');
        } else {}
        /**
         * Inscription/Reset success :
         * -> trigger modal popup.
         */
        if ($('body#accueil > section#popup > article.modal-content > div.modal-body > div.alert-inscription').length) {
            const fondPopup = $('body#accueil > section#popup');
            const modPopup = $('body#accueil > section#popup > article.modal-content');
            const alertPopup = $('body#accueil > section#popup > article.modal-content > div.modal-body > div.alert-inscription');
            $(fondPopup).removeClass('hidden');
            /**
             * Click sur bouton close :
             * -> ferme le modal,
             * -> supprime alert.
             */
            const btnClosePopup = $('body#accueil > section#popup > article.modal-content > div.modal-header > i');
            $(btnClosePopup).on('click', function () {
                $(fondPopup).addClass('hidden');
                $(alertPopup).remove();
            });
            /**
             * Click en dehors du contenu du modal :
             * -> le ferme,
             * -> supprime alert.
             */
            $(fondPopup).on('click', function (event) {
                if (!$(event.target).closest($(modPopup)).length &&
                    !$(event.target).is($(modPopup))) {
                    $(fondPopup).addClass('hidden');
                    $(alertPopup).remove();
                } else {}
            });
        } else if ($('body#accueil > section#popup > article.modal-content > div.modal-body > div.alert-reset').length) {
            const fondPopup = $('body#accueil > section#popup');
            const modPopup = $('body#accueil > section#popup > article.modal-content');
            const alertPopup = $('body#accueil > section#popup > article.modal-content > div.modal-body > div.alert-reset');
            $(fondPopup).removeClass('hidden');
            /**
             * Click sur bouton close :
             * -> ferme le modal,
             * -> supprime alert.
             */
            const btnClosePopup = $('body#accueil > section#popup > article.modal-content > div.modal-header > i');
            $(btnClosePopup).on('click', function () {
                $(fondPopup).addClass('hidden');
                $(alertPopup).remove();
            });
            /**
             * Click en dehors du contenu du modal :
             * -> le ferme,
             * -> supprime alert.
             */
            $(fondPopup).on('click', function (event) {
                if (!$(event.target).closest($(modPopup)).length &&
                    !$(event.target).is($(modPopup))) {
                    $(fondPopup).addClass('hidden');
                    $(alertPopup).remove();
                } else {}
            });
        } else {}
        /**
         * Show/hide password
         */
        $('body#accueil > section#login > article.modal-content > div.modal-body > form > div.card-body > div > fieldset:nth-of-type(2) > div.form-group > i.far.fa-eye-slash').on('mousedown', function () {
            $(this).prev().attr('type', 'text');
        }).on('mouseup', function () {
            $(this).prev().attr('type', 'password');
        }).on('mouseout', function () {
            $(this).prev().attr('type', 'password');
        });
    } else {}


    /**
     * RESET MDP
     */
    const fondModReset = $('body#accueil > section#reset-mdp');
    if ($(fondModReset).length) {
        /**
         * Click sur bouton close :
         * -> ferme le modal,
         * -> supprime alerts.
         */
        const btnCloseReset = $('body#accueil > section#reset-mdp > article.modal-content > div.modal-header > i');
        $(btnCloseReset).on('click', function () {
            $(fondModReset).addClass('hidden');
            $(alerts).remove();
        });
        /**
         * Click sur bouton 'Annuler' :
         * -> ferme le modal,
         * -> supprime alerts,
         * -> vide l'input email (nécessaire après redirect()->back()->withInputs() qui set la 'default value' avec old()).
         */
        const btnAnnulerReset = $('body#accueil > section#reset-mdp > article.modal-content > div.modal-body > form > div.card-footer > button[type=reset]');
        $(btnAnnulerReset).on('click', function () {
            $(fondModReset).addClass('hidden');
            $(alerts).remove();
            const inputEmail = $('body#accueil > section#reset-mdp input#identifiantReset');
            $(inputEmail).removeAttr('value');
        });
        /**
         * Gestion erreurs :
         * -> si erreur ou alert, trigger modal.
         */
        if ($('body#accueil > section#reset-mdp > article.modal-content > div.modal-body > div.alert ').length) {
            $(fondModReset).removeClass('hidden');
        } else {}
        if ($('body#accueil > section#reset-mdp > article.modal-content > div.modal-body > form > div.card-body > fieldset > div.form-group > div.invalid-feedback').length) {
            $(fondModReset).removeClass('hidden');
        } else {}
    } else []


    /**
     * INSCRIPTION
     */
    const fondModInscription = $('body#accueil > section#inscription');
    if ($(fondModInscription).length) {
        const btnInscription = $('body#accueil > header > nav.navbar > div#navbarSupportedContent > ul.navbar-nav > li#btnInscription > a');
        const modInscription = $('body#accueil > section#inscription > article.modal-content');
        /**
         * Click btn navbar header "S'inscrire" :
         * -> ouvre le modal d'inscription.
         */
        $(btnInscription).on('click', function (event) {
            event.preventDefault();
            $(fondModInscription).removeClass('hidden');
        });
        /**
         * Click sur bouton close :
         * -> ferme le modal,
         * -> supprime alerts.
         */
        const btnCloseInscription = $('body#accueil > section#inscription > article.modal-content > div.modal-header > i');
        $(btnCloseInscription).on('click', function () {
            $(fondModInscription).addClass('hidden');
            $(alerts).remove();
        });
        /**
         * Click sur bouton 'Annuler' :
         * -> ferme le modal (et attribut html vide les champs),
         * -> supprime alerts.
         * -> vide les inputs (nécessaire après redirect()->back()->withInputs() qui set les 'defaults values' avec old()).
         */
        const btnAnnulerInscription = $('body#accueil > section#inscription > article.modal-content > div.modal-body > form > div.card-footer > button[type=reset]');
        $(btnAnnulerInscription).on('click', function () {
            $(fondModInscription).addClass('hidden');
            $(alerts).remove();
            const inputsText = $('body#accueil > section#inscription input[type=text]');
            const inputEmail = $('body#accueil > section#inscription input[type=email]');
            const inputTel = $('body#accueil > section#inscription input[type=tel]');
            const select = $('body#accueil > section#inscription select#civilite');
            const inputCheckbox = $('body#accueil > section#inscription input[type=checkbox]');
            $(inputsText).removeAttr('value');
            $(inputEmail).removeAttr('value');
            $(inputTel).removeAttr('value');
            $(select).children('option').removeAttr('selected');
            $(inputCheckbox).removeAttr('checked');
        });
        /**
         * Gestion erreurs :
         * -> si erreur ou alert, trigger modal.
         */
        if ($('body#accueil > section#inscription > article.modal-content > div.modal-body > div.alert ').length) {
            $(btnInscription).trigger('click');
        } else {}
        if ($('body#accueil > section#inscription > article.modal-content > div.modal-body > form > div.card-body > fieldset > div.form-group > div.invalid-feedback').length) {
            $(btnInscription).trigger('click');
        } else {}
    } else {}

});
